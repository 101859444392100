<template>
  <div class="center">
    <div class="container">
        <h1  style="margin-bottom: 32px;" class="mtext">Запись на первую групповую бесплатную пробную тренировку</h1>
        <div class="datepicker-place">
            <p class="mtext">Выберите дату*</p>
            <div v-if="showDesk" class="date-con">
                <div class="datepicker-deskcontrainer">
                    <div v-on:click="loadBack"><img class="navarrs" src="../assets/img/leftarr.png"></div>
                    <div v-for="(item,index) in dates" :key="item.id" class="date-deskpick" v-on:click="selectDate(index)" ref="dates" :date=item.getDate()><div ref="dhead" class="date-head">{{item.getDate()}}</div><div class="aval-places">Места: {{item.getVal()}}</div></div>
                    <div v-on:click="loadMore"><img class="navarrs" src="../assets/img/rightarr.png"></div>
                </div>
            </div>
            <div v-if="showMobile" class="date-con">
                <div :class=datehClass @click="toogleDates">
                    <img class="inicons" src="../assets/img/calendar.png">
                    <span class="dataname">{{ formattedDate }}</span>
                    <span class="arrow-icon">&#9662;</span>
                </div>
                <div v-if="showContrainer" class="datepicker-contrainer">
                    <div v-for="(item,index) in dates" :key="item.id" class="date-pick" v-on:click="selectDate(index)" ref="dates" :date=item.getDate()><div>{{item.getDate()}}</div><div class="aval-places">Места: {{item.getVal()}}</div></div>
                    <div v-on:click="loadMore" class="date-end">Показать больше</div>
                </div>
            </div>
        </div>
        <div class="attach-text">
            <div>
                <div class="dtext">* Тренировка состоится при количестве участников в группе от 5 и более человек.</div>
                <div class="dtext">* Для бесплатных пробных тренировок перенос на другую дату после бронирования невозможен. Будьте, пожалуйста, внимательны.</div>
                <div class="dtext">* Количество мест ограничено.</div>
            </div>
            <div>
                <div style="margin-top: 32px;" class="mtext">Если вам понравилась первая тренировка, обратитесь на ресепшн для записи на вторую бесплатную тренировку</div>
            </div>
            <div>
                <div style="margin-top: 18px;" class="mtext">Тренировки проводятся:</div>
                <div  style="margin-bottom: 18px;" class="dtext">Вы можете выбрать  обе тренировки на гольф симуляторе или обе в поле. Либо 1 тренировку на гольф симуляторе и 1 тренировку на поле.</div>
            </div>
        </div>
        <div class="place-selection">
            <div class="place-club" :class="{ activep: isActivec}">
                <div class="place-head">Гольф-центр «Петергоф» (гольф-симуляторы)</div>
                <div>
                    <img class="place-img" src="../assets/img/map.png">
                    наб. Фонтанки д.142
                </div>
                <div>
                    <img class="place-img" src="../assets/img/calicform.png">
                    С 1АПРЕЛЯ ПО 15 МАЯ 2024 ГОДА
                </div>
            </div>
            <div class="place-club" :class="{ activep: isActivep}">
                <div class="place-head">Гольф-клуб «Петергоф» (гольф-поле)</div>
                <div>
                    <img class="place-img" src="../assets/img/map.png">
                    г. Петергоф, ул. Гофмейстерская д.1
                </div>
                <div>
                    <img class="place-img" src="../assets/img/calicform.png">
                    С 15 МАЯ ПО 15 ОКТЯБРЯ 2024 ГОДА
                </div>
            </div>
        </div>
        <div class="cform" ref="location">         
            <div class="mtext">Укажите Ваши контактные данные</div>
            <div class="inputs">
                <img class="inicons" src="../assets/img/avatar.png">
                 <input v-model="name" type="text" placeholder="Ваше имя" disabled>
                 <span v-if="nameErr" class="error" id="error_name">&#33;</span>
            </div>
            <div class="inputs">
                <img class="inicons" src="../assets/img/telephone.png">
                <input v-model="tel" type="text" placeholder="Ваш телефон" disabled>
                <span v-if="phoneErr" class="error" id="error_tel">&#33;</span>
            </div>
            <div class="inputs">
                <img class="inicons" src="../assets/img/email.png">
                <input v-model="email" type="text" placeholder="Ваш email" disabled>
                <span v-if="emailErr" class="error" id="error_email">&#33;</span>
            </div>
            <div class="mtext">Комментарий к бронированию</div>
            <div class="comm-input"><textarea v-model="comment" placeholder="Добавьте комментарий"></textarea></div>
            <div class="checks">
                <div class="ofer-ch">
                    <input v-model="offer" type="checkbox" id="ofer-ch" disabled>
                    <label for="ofer-ch">Согласен с договором офертой</label>
                </div>
                <div class="privacy-check">
                    <input v-model="privacy" type="checkbox" id="privacy-ch" disabled>
                    <label for="privacy-ch">Согласен на обработку персональных данных</label>
                </div>
            </div>
        </div>
        <div class="footerform">
            <button @click="getBookValues" class="post-btn" type="button">Забронировать</button>
            <img class="ptr-logo" src="../assets/img/logo_footer.png" alt="">
        </div>
    </div>
  </div>

</template>

<script>
import { BookingServiceClient } from '../peter_grpc_web_pb.js';
import { AvailableRequest,BookingRequest, GetRequest } from '../peter_pb.js';
import { useRoute } from 'vue-router'

export class PeterBooking {
  constructor(select_date, prime_name, prime_phone, prime_email,comment,place) {
    this.date = select_date;
    this.pname = prime_name;
    this.pphone = prime_phone;
    this.pemail = prime_email;
    this.comm = comment;
    this.splace = place;
  }

  sendBooking(){
    const client = new BookingServiceClient('https://book.peterhofgolf.ru');
    
    let request = new BookingRequest();
    request.setSelecteddate(this.date);
    request.setPrimename(this.pname);
    request.setPrimephone(this.pphone);
    request.setPrimeemail(this.pemail);
    request.setCommentvalue(this.comm);
    request.setSelectedplace(this.splace);
    client.sendBooking(request, {}, (err, response) => {
      if (err) {
        console.error("gRPC error:", err);
      } else {
        console.log(response.getBookid());
      }
    });
  }
}
export default {
  el: 'SecondPage',
  name: "SecondPage",
  data() {
    return {
      name: "",
      tel: "",
      email: "",
      comment: "Второе бронирование",
      offer: true,
      privacy: true,
      datehClass: "inputs-data",
      selectedDay: null,
      showContrainer:true,
      dates: null,
      nameErr: false,
      phoneErr: false,
      emailErr: false,
      isActivec: null,
      isActivep: null,
      showMobile: window.innerWidth < 500,
      showDesk: window.innerWidth > 500,
    //   location: null,
      formatDate: function(date){
        if (date.toLocaleDateString("en-US") != new Date().toLocaleDateString("en-US")){
          this.hours = 1
        }else{
          this.hours = new Date().getHours()
        }
        let day = date.getDate();
        let month = date.getMonth()+1;
        if (month < 10){
          month = "0" + month
        }
        if (day < 10){
          day = "0" + day
        }
        let year = date.getFullYear();
        let format1 = day + "." + month + "." + year;
        return format1
      },
    }
  },
  computed: {
    formattedDate() {
        return this.selectedDay 
          ? `${this.selectedDay}`
          : 'Дата';
      },
  },
  mounted() {
    this.fetchAvailableDays("");
    this.getPlace();
    this.getBooking();
  },
  methods: {
    getBooking(){
        const route = useRoute()
        const client = new BookingServiceClient('https://book.peterhofgolf.ru');
        let request = new GetRequest();
        console.log(route.params.id);
        request.setBookid(route.params.id);
        client.getBooking(request, {}, (err, response) => {
        if (err) {
            console.error("gRPC error:", err);
        } else {
            this.processBooking(response);
        }
        });
    },
    processBooking(response){
        this.name = response.getPrimename();
        this.tel = response.getPrimephone();
        this.email = response.getPrimeemail();
    },
    loadBack(){
        console.log(this.$refs.dates[0].getAttribute('date'));
        let ldayArr = this.$refs.dates[0].getAttribute('date').split('.')
        let lday = new Date(ldayArr[1]+'.'+ldayArr[0]+'.'+ldayArr[2])
        lday.setDate(lday.getDate() - 28);
        this.fetchAvailableDays(lday.toLocaleDateString())
    },
    loadMore(){
        console.log(this.$refs.dates[4].getAttribute('date'));
        this.fetchAvailableDays(this.$refs.dates[4].getAttribute('date'))
    },
    getPlace(){
        this.checkDateEntrance(new Date());
    },
    checkDateEntrance(date) {
        if (date >= new Date('2024-04-15') && date <= new Date('2024-05-15')){
            console.log("centr");
            this.place = "centr";
            this.isActivec = true;
        }
        else if(date >= new Date('2024-05-15') && date <= new Date('2024-10-15')){
            this.place = "pole";
            console.log("pole");
            this.isActivep = true;
        }
        else{
            console.log("def");
            this.place = "centr"; // убрать на проде
            this.isActivec = true;
        }
    },
    getBookValues(){
        let formerr = false
        if (this.privacy != false || this.offer != false){
            if (!this.name || this.name.length < 5) {
                console.log("nameErr");
                this.nameErr = true;
                formerr = true;
            }
            let phonePattern = /^\+?[1-9]\d{1,14}$/; // Пример простого паттерна для международного номера
            if (!this.tel.match(phonePattern)) {
                console.log("phoneErr");
                this.phoneErr = true;
                formerr = true;
            }
            if (!this.email.match(/^[^@]+@[^@]+\.[^@]+$/)) {
                console.log("emailErr");
                this.emailErr = true;
                formerr = true;
            }
            if (!formerr){
                const myBooking = new PeterBooking(
                    this.selectedDay,
                    this.name, 
                    this.tel, 
                    this.email, 
                    this.comment,
                    this.place,
                );
                console.log(myBooking);
                myBooking.sendBooking()
                this.$router.push('/end')
            }else{
                const refVar = "location"
                this.$refs[refVar].scrollIntoView({ behavior: "smooth" });            }
        }
    },
    fetchAvailableDays(startDate) {
        const client = new BookingServiceClient('https://book.peterhofgolf.ru');
        let request = new AvailableRequest();
        request.setStartdate(startDate || "")
        client.sendAvailable(request, {}, (err, response) => {
        if (err) {
            console.error("gRPC error:", err);
        } else {
            this.processDateSlots(response.getDateslotSlotsList());
        }
        });
    },
    processDateSlots(dateSlots) {
        this.dates = dateSlots;
    },
    selectDate(index){
        this.selectedDay = this.$refs.dates[index].getAttribute('date')
        this.toogleDates();
        this.$refs.dates.forEach(element => {
            element.className = "date-deskpick";
        });
        if (this.showDesk) {
            this.$refs.dhead.forEach(element => {
            element.className = "date-head";
            });
            this.$refs.dhead[index].className = "date-head-active";
        }
        this.$refs.dates[index].className = "date-deskpick-active";

    },
    toogleDates(){
        console.log(this.$refs.dates);
        this.showContrainer = !this.showContrainer;
        console.log(this.datehClass);
        if (this.datehClass == 'inputs-datadef'){
            this.datehClass = 'inputs-data';
        }else{
            this.datehClass = 'inputs-datadef';
        }
        this.fetchAvailableDays("");


    }
  },
}
</script>

<style>

</style>

