import { createWebHistory, createRouter } from "vue-router";
import MainPage from "@/components/MainPage.vue";
import CancelPage from "@/components/CancelPage.vue";
import SecondPage from "@/components/SecondPage.vue";
import EndPage from "@/components/EndPage.vue";

const routes = [
  {
    path: "/",
    name: "StartPage",
    redirect: "/training"
  },
  {
    path: "/training",
    name: "MainPage",
    component: MainPage,
  },
  {
    path: "/cancel/:id",
    name: "CancelPage",
    component: CancelPage,
  },
  {
    path: "/second/:id",
    name: "SecondPage",
    component: SecondPage,
  },
  {
    path: "/end",
    name: "EndPage",
    component: EndPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;