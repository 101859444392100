<template>
        <RouterView />
</template>
<script>

</script>

<style>
body{
    display: block;
    margin:0;
}
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    justify-content: center;
    background: url(./assets/img/c9b1c1a2f70114b3500b08386e841b24.jpeg) no-repeat center center fixed #FFFFFF;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
</style>

  