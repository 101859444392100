/**
 * @fileoverview gRPC-Web generated client stub for peter
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.peter = require('./peter_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.peter.BookingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.peter.BookingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.peter.AvailableRequest,
 *   !proto.peter.AvailableResponse>}
 */
const methodDescriptor_BookingService_SendAvailable = new grpc.web.MethodDescriptor(
  '/peter.BookingService/SendAvailable',
  grpc.web.MethodType.UNARY,
  proto.peter.AvailableRequest,
  proto.peter.AvailableResponse,
  /**
   * @param {!proto.peter.AvailableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.peter.AvailableResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.peter.AvailableRequest,
 *   !proto.peter.AvailableResponse>}
 */
const methodInfo_BookingService_SendAvailable = new grpc.web.AbstractClientBase.MethodInfo(
  proto.peter.AvailableResponse,
  /**
   * @param {!proto.peter.AvailableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.peter.AvailableResponse.deserializeBinary
);


/**
 * @param {!proto.peter.AvailableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.peter.AvailableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.peter.AvailableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.peter.BookingServiceClient.prototype.sendAvailable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/peter.BookingService/SendAvailable',
      request,
      metadata || {},
      methodDescriptor_BookingService_SendAvailable,
      callback);
};


/**
 * @param {!proto.peter.AvailableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.peter.AvailableResponse>}
 *     Promise that resolves to the response
 */
proto.peter.BookingServicePromiseClient.prototype.sendAvailable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/peter.BookingService/SendAvailable',
      request,
      metadata || {},
      methodDescriptor_BookingService_SendAvailable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.peter.BookingRequest,
 *   !proto.peter.BookingResponse>}
 */
const methodDescriptor_BookingService_SendBooking = new grpc.web.MethodDescriptor(
  '/peter.BookingService/SendBooking',
  grpc.web.MethodType.UNARY,
  proto.peter.BookingRequest,
  proto.peter.BookingResponse,
  /**
   * @param {!proto.peter.BookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.peter.BookingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.peter.BookingRequest,
 *   !proto.peter.BookingResponse>}
 */
const methodInfo_BookingService_SendBooking = new grpc.web.AbstractClientBase.MethodInfo(
  proto.peter.BookingResponse,
  /**
   * @param {!proto.peter.BookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.peter.BookingResponse.deserializeBinary
);


/**
 * @param {!proto.peter.BookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.peter.BookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.peter.BookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.peter.BookingServiceClient.prototype.sendBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/peter.BookingService/SendBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_SendBooking,
      callback);
};


/**
 * @param {!proto.peter.BookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.peter.BookingResponse>}
 *     Promise that resolves to the response
 */
proto.peter.BookingServicePromiseClient.prototype.sendBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/peter.BookingService/SendBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_SendBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.peter.GetRequest,
 *   !proto.peter.GetResponse>}
 */
const methodDescriptor_BookingService_GetBooking = new grpc.web.MethodDescriptor(
  '/peter.BookingService/GetBooking',
  grpc.web.MethodType.UNARY,
  proto.peter.GetRequest,
  proto.peter.GetResponse,
  /**
   * @param {!proto.peter.GetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.peter.GetResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.peter.GetRequest,
 *   !proto.peter.GetResponse>}
 */
const methodInfo_BookingService_GetBooking = new grpc.web.AbstractClientBase.MethodInfo(
  proto.peter.GetResponse,
  /**
   * @param {!proto.peter.GetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.peter.GetResponse.deserializeBinary
);


/**
 * @param {!proto.peter.GetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.peter.GetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.peter.GetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.peter.BookingServiceClient.prototype.getBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/peter.BookingService/GetBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetBooking,
      callback);
};


/**
 * @param {!proto.peter.GetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.peter.GetResponse>}
 *     Promise that resolves to the response
 */
proto.peter.BookingServicePromiseClient.prototype.getBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/peter.BookingService/GetBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.peter.CancelRequest,
 *   !proto.peter.CancelResponse>}
 */
const methodDescriptor_BookingService_CancelBooking = new grpc.web.MethodDescriptor(
  '/peter.BookingService/CancelBooking',
  grpc.web.MethodType.UNARY,
  proto.peter.CancelRequest,
  proto.peter.CancelResponse,
  /**
   * @param {!proto.peter.CancelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.peter.CancelResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.peter.CancelRequest,
 *   !proto.peter.CancelResponse>}
 */
const methodInfo_BookingService_CancelBooking = new grpc.web.AbstractClientBase.MethodInfo(
  proto.peter.CancelResponse,
  /**
   * @param {!proto.peter.CancelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.peter.CancelResponse.deserializeBinary
);


/**
 * @param {!proto.peter.CancelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.peter.CancelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.peter.CancelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.peter.BookingServiceClient.prototype.cancelBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/peter.BookingService/CancelBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_CancelBooking,
      callback);
};


/**
 * @param {!proto.peter.CancelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.peter.CancelResponse>}
 *     Promise that resolves to the response
 */
proto.peter.BookingServicePromiseClient.prototype.cancelBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/peter.BookingService/CancelBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_CancelBooking);
};


module.exports = proto.peter;

