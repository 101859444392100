<template>
  <h1>Отмена бронирования...</h1>
</template>

<script>
import { BookingServiceClient } from '../peter_grpc_web_pb.js';
import { CancelRequest } from '../peter_pb.js';
import { useRoute } from 'vue-router'
export default {
  el: 'CancelPage',
  name: "CancelPage",
  mounted() {
    this.cancelBooking();
  },
  methods: {
    cancelBooking(){
      const route = useRoute()
      const client = new BookingServiceClient('https://book.peterhofgolf.ru');
      let request = new CancelRequest();
      console.log(route.params.id);
      request.setBookid(route.params.id);
      client.cancelBooking(request, {}, (err, response) => {
      if (err) {
          console.error("gRPC error:", err);
      } else {
          this.processCancel(response);
      }
      });
    },
    processCancel(){
      window.close()
    },
  },
}
</script>