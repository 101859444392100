<template>
  <div class="center">
    <div class="container">
        <h1  style="margin-bottom: 32px;" class="mtext">Запись на первую групповую бесплатную пробную тренировку по четвергам c 19:00</h1>
        <div class="datepicker-place">
            <p class="mtext">Выберите дату*</p>
            <div v-if="showDesk" class="date-con">
                <div class="datepicker-deskcontrainer">
                    <div v-on:click="loadBack"><img class="navarrs" src="../assets/img/leftarr.png"></div>
                    <div v-for="(item,index) in dates" :key="item.id" class="date-deskpick" v-on:click="selectDate(index)" ref="dates" :date=item.getDate()><div ref="dhead" class="date-head">{{item.getDate()}}</div><div class="aval-places">Места: {{item.getVal()}}</div></div>
                    <div v-on:click="loadMore"><img class="navarrs" src="../assets/img/rightarr.png"></div>
                </div>
            </div>
            <div v-if="showMobile" class="date-con">
                <div :class=datehClass @click="toogleDates">
                    <img class="inicons" src="../assets/img/calendar.png">
                    <span class="dataname">{{ formattedDate }}</span>
                    <span class="arrow-icon">&#9662;</span>
                </div>
                <div v-if="showContrainer" class="datepicker-contrainer">
                    <div v-for="(item,index) in dates" :key="item.id" class="date-pick" v-on:click="selectDate(index)" ref="dates" :date=item.getDate()><div>{{item.getDate()}}</div><div class="aval-places">Места: {{item.getVal()}}</div></div>
                    <div v-on:click="loadMore" class="date-end">Показать больше</div>
                </div>
            </div>
        </div>
        <div class="attach-text">
            <div>
                <div class="dtext">* Тренировка состоится при количестве участников в группе от 5 и более человек.</div>
                <div class="dtext">* Для бесплатных пробных тренировок перенос на другую дату после бронирования невозможен. Будьте, пожалуйста, внимательны.</div>
                <div class="dtext">* Количество мест ограничено.</div>
            </div>
            <div>
                <div style="margin-top: 32px;" class="mtext">Если вам понравилась первая тренировка, обратитесь на ресепшн для записи на вторую бесплатную тренировку</div>
            </div>
            <div>
                <div style="margin-top: 18px;" class="mtext">Тренировки проводятся:</div>
                <div  style="margin-bottom: 18px;" class="dtext">Вы можете выбрать  обе тренировки на гольф симуляторе или обе в поле. Либо 1 тренировку на гольф симуляторе и 1 тренировку на поле.</div>
            </div>
        </div>
        <div class="place-selection">
            <div class="place-club" :class="{ activep: isActivec}">
                <div class="place-head">Гольф-центр «Петергоф» (гольф-симуляторы)</div>
                <div>
                    <img class="place-img" src="../assets/img/map.png">
                    наб. Фонтанки д.142
                </div>
                <div>
                    <img class="place-img" src="../assets/img/calicform.png">
                    С 1АПРЕЛЯ ПО 15 МАЯ 2024 ГОДА
                </div>
            </div>
            <div class="place-club" :class="{ activep: isActivep}">
                <div class="place-head">Гольф-клуб «Петергоф» (гольф-поле)</div>
                <div>
                    <img class="place-img" src="../assets/img/map.png">
                    г. Петергоф, ул. Гофмейстерская д.1
                </div>
                <div>
                    <img class="place-img" src="../assets/img/calicform.png">
                    С 15 МАЯ ПО 15 ОКТЯБРЯ 2024 ГОДА
                </div>
            </div>
        </div>
        <div class="cform" ref="location">         
            <div class="mtext">Укажите Ваши контактные данные</div>
            <div class="inputs">
                <img class="inicons" src="../assets/img/avatar.png">
                 <input v-model="name" type="text" placeholder="Ваше имя">
                 <span v-if="nameErr" class="error" id="error_name">&#33;</span>
            </div>
            <div class="inputs">
                <img class="inicons" src="../assets/img/telephone.png">
                <input v-model="tel" type="text" placeholder="Ваш телефон">
                <span v-if="phoneErr" class="error" id="error_tel">&#33;</span>
            </div>
            <div class="inputs">
                <img class="inicons" src="../assets/img/card.png">
                <input v-model="card" type="text" placeholder="Номер карточки">
                <span v-if="cardErr" class="error" id="error_card">&#33;</span>
            </div>
            <div class="inputs">
                <img class="inicons" src="../assets/img/email.png">
                <input v-model="email" type="text" placeholder="Ваш email">
                <span v-if="emailErr" class="error" id="error_email">&#33;</span>
            </div>
            <div class="mtext">Комментарий к бронированию</div>
            <div class="comm-input"><textarea v-model="comment" placeholder="Добавьте комментарий"></textarea></div>
            <div class="checks">
                <div class="ofer-ch">
                    <input v-model="offer" type="checkbox" id="ofer-ch">
                    <label for="ofer-ch">Согласен с договором офертой</label>
                </div>
                <div class="privacy-check">
                    <input v-model="privacy" type="checkbox" id="privacy-ch">
                    <label for="privacy-ch">Согласен на обработку персональных данных</label>
                </div>
            </div>
        </div>
        <div class="footerform">
            <button @click="getBookValues" class="post-btn" type="button">Забронировать</button>
            <img class="ptr-logo" src="../assets/img/logo_footer.png" alt="">
        </div>
    </div>
  </div>

</template>

<script>
import { BookingServiceClient } from '../peter_grpc_web_pb.js';
import { AvailableRequest,BookingRequest } from '../peter_pb.js';
export class PeterBooking {
  constructor(select_date, prime_name, prime_phone, prime_email,comment,place,card_num) {
    this.date = select_date;
    this.pname = prime_name;
    this.pphone = prime_phone;
    this.pemail = prime_email;
    this.comm = comment;
    this.splace = place;
    this.card = card_num;
  }

  sendBooking(){
    const client = new BookingServiceClient('https://book.peterhofgolf.ru');
    
    let request = new BookingRequest();
    request.setSelecteddate(this.date);
    request.setPrimename(this.pname);
    request.setPrimephone(this.pphone);
    request.setPrimeemail(this.pemail);
    request.setCommentvalue(this.comm);
    request.setSelectedplace(this.splace);
    request.setCardnum(this.card);

    client.sendBooking(request, {}, (err, response) => {
      if (err) {
        console.error("gRPC error:", err);
      } else {
        console.log(response.getBookid());
      }
    });
  }
}
export default {
  el: 'MainPage',
  name: "MainPage",
  data() {
    return {
      name: "",
      tel: "",
      email: "",
      comment: "",
      offer: false,
      privacy: false,
      datehClass: "inputs-data",
      selectedDay: null,
      showContrainer:true,
      dates: null,
      nameErr: false,
      phoneErr: false,
      emailErr: false,
      isActivec: null,
      isActivep: null,
      showMobile: window.innerWidth < 500,
      showDesk: window.innerWidth > 500,
    //   location: null,
      formatDate: function(date){
        if (date.toLocaleDateString("en-US") != new Date().toLocaleDateString("en-US")){
          this.hours = 1
        }else{
          this.hours = new Date().getHours()
        }
        let day = date.getDate();
        let month = date.getMonth()+1;
        if (month < 10){
          month = "0" + month
        }
        if (day < 10){
          day = "0" + day
        }
        let year = date.getFullYear();
        let format1 = day + "." + month + "." + year;
        return format1
      },
    }
  },
  computed: {
    formattedDate() {
        return this.selectedDay 
          ? `${this.selectedDay}`
          : 'Дата';
      },
  },
  mounted() {
    this.fetchAvailableDays("");
    this.getPlace();
  },
  methods: {
    loadBack(){
        console.log(this.$refs.dates[0].getAttribute('date'));
        let ldayArr = this.$refs.dates[0].getAttribute('date').split('.')
        let lday = new Date(ldayArr[1]+'.'+ldayArr[0]+'.'+ldayArr[2])
        lday.setDate(lday.getDate() - 28);
        if (lday > new Date()){
            this.fetchAvailableDays(lday.toLocaleDateString())
        }
    },
    loadMore(){
        console.log(this.$refs.dates[4].getAttribute('date'));
        this.fetchAvailableDays(this.$refs.dates[4].getAttribute('date'))
    },
    getPlace(){
        this.checkDateEntrance(new Date());
    },
    checkDateEntrance(date) {
        if (date >= new Date('2024-04-15') && date <= new Date('2024-05-15')){
            console.log("centr");
            this.place = "centr";
            this.isActivec = true;
        }
        else if(date >= new Date('2024-05-15') && date <= new Date('2024-10-15')){
            this.place = "pole";
            console.log("pole");
            this.isActivep = true;
        }
        else{
            console.log("def");
            this.place = "centr"; // убрать на проде
            this.isActivec = true;
        }
    },
    getBookValues(){
        let formerr = false
        if (this.privacy != false || this.offer != false){
            if (!this.name || this.name.length < 5) {
                console.log("nameErr");
                this.nameErr = true;
                formerr = true;
            }
            let phonePattern = /^\+?[1-9]\d{1,14}$/; // Пример простого паттерна для международного номера
            if (!this.tel.match(phonePattern)) {
                console.log("phoneErr");
                this.phoneErr = true;
                formerr = true;
            }
            if (!this.email.match(/^[^@]+@[^@]+\.[^@]+$/)) {
                console.log("emailErr");
                this.emailErr = true;
                formerr = true;
            }
            if (!this.card.match(/\d+/) && this.card.length > 4) {
                console.log("cardErr");
                this.cardErr = true;
                formerr = true;
            }
            if (!formerr){
                const myBooking = new PeterBooking(
                    this.selectedDay,
                    this.name, 
                    this.tel, 
                    this.email, 
                    this.comment,
                    this.place,
                    this.card
                );
                myBooking.sendBooking()
                this.$router.push('/end')
            }else{
                const refVar = "location"
                this.$refs[refVar].scrollIntoView({ behavior: "smooth" });            }
        }
    },
    fetchAvailableDays(startDate) {
        const client = new BookingServiceClient('https://book.peterhofgolf.ru');
        let request = new AvailableRequest();
        request.setStartdate(startDate || "")
        client.sendAvailable(request, {}, (err, response) => {
        if (err) {
            console.error("gRPC error:", err);
        } else {
            this.processDateSlots(response.getDateslotSlotsList());
        }
        });
    },
    processDateSlots(dateSlots) {
        this.dates = dateSlots;
    },
    selectDate(index){
        this.selectedDay = this.$refs.dates[index].getAttribute('date')
        this.toogleDates();
        this.$refs.dates.forEach(element => {
            element.className = "date-deskpick";
        });
        if (this.showDesk) {
            this.$refs.dhead.forEach(element => {
            element.className = "date-head";
            });
            this.$refs.dhead[index].className = "date-head-active";
        }
        this.$refs.dates[index].className = "date-deskpick-active";

    },
    toogleDates(){
        console.log(this.$refs.dates);
        this.showContrainer = !this.showContrainer;
        console.log(this.datehClass);
        if (this.datehClass == 'inputs-datadef'){
            this.datehClass = 'inputs-data';
        }else{
            this.datehClass = 'inputs-datadef';
        }
        this.fetchAvailableDays("");


    }
  },
}
</script>

<style>

img{
    width: 32px;
}
@media (min-device-width: 1px) and (max-device-width: 500px) {
    .center{
        width: 95%;
    }
    .ptr-logo{
        width: 24%;
        padding-left: 25px;
    }
    .post-btn{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 8px;
        gap: 8px;
        width: 64%;
        background: #104112;
        border-radius: 20px;
        color: white;
        border: unset;
        font-size: 19px;
    }
    .checks{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 10px;
        gap: 7px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 20px;
        margin: 15px 0 15px 0;
    }
    .comm-input{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 20px;
        gap: 8px;
        height: 120px;
        background: #FFFFFF;
        border-radius: 20px;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 16px 0 16px 0;

    }
    .place-club{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 12px 10px;
        gap: 12px;
        background: #b1acacbf;
        border-radius: 16px;
        margin: 10px 0 10px 0;
    }
    .inputs{
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        padding: 0px 16px 0px 0px;
        gap: 8px;
        height: 44px;
        background: #FFFFFF;
        border-bottom: 1px solid #FFFFFF;
        border-radius: 100px 100px 100px 81px;
        margin: 20px 0 20px 0;
    }
    .footerform{
        margin-top: 20px;
        display: flex;
        flex-direction: row;
    }
}
@media (min-device-width: 501px) and (max-device-width: 999px) {
    .inicons {
        background: #FFFFFF;
        border-radius: 18.25px;
        position: relative;
        width: 30px;
        border: solid 10px white;
    }
    .center {
        width: 90%;
        margin: 25px 25px 25px 25px;
    }
    .date-deskpick-active {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 14px 0px;
        gap: 4px;
        width: 100px;
        height: 40px;
        background: #698A97;
        border-radius: 20px;
        margin: 10px 10px 10px 10px;
        cursor: pointer;
    }
    .date-head {
        font-weight: 600;
        color: #0a0a0ac7;
    }
    .date-head-active {
        font-weight: 600;
        color: #ffffff;
    }
    .ptr-logo {
        width: 11%;
        padding-left: 25px;
    }
    .post-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 8px;
        gap: 8px;
        width: 33%;
        height: 56px;
        background: #104112;
        border-radius: 20px;
        color: white;
        border: unset;
        font-size: 19px;
    }
    .checks {
        display: inline-flex;
        padding: 10px 10px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 20px;
        margin: 15px 0 15px 0;
        width: 63%;
        height: 30px;
        flex-direction: row;
        align-items: center;
    }
    .comm-input {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 20px;
        gap: 8px;
        height: 70px;
        background: #FFFFFF;
        border-radius: 20px;
        margin: 16px 0 16px 0;
        width: 61%;
    }
    .place-club {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        padding: 12px 10px;
        gap: 12px;
        background: #b1acacbf;
        border-radius: 16px;
        margin: 10px 10px 10px 10px;
    }
    .inputs {
        box-sizing: border-box;
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        padding: 0px 16px 0px 0px;
        gap: 8px;
        height: 44px;
        background: #FFFFFF;
        border-bottom: 1px solid #FFFFFF;
        border-radius: 100px 100px 100px 81px;
        margin: 20px 2px 20px 2px;
        width: 32%;
    }
    .date-deskpick {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 14px 0px;
        gap: 4px;
        width: 100px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 13px;
        margin: 10px 10px 10px 10px;
        cursor: pointer;
    }
    .datepicker-deskcontrainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .date-con{
        display: flex;
        justify-content: center;
    }
    .navarrs{
        background: #FFFFFF;
        border-radius: 18.25px;
        position: relative;
        width: 20px;
        border: solid 10px white;
    }
    .footerform {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .place-selection{
        display: flex;
        flex-direction: row;
        margin: 16px 0 16px 0;
    }
}
@media (min-device-width: 1000px) {
    .date-deskpick-active {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 14px 0px;
        gap: 4px;
        width: 136px;
        height: 40px;
        background: #698A97;
        border-radius: 20px;
        margin: 10px 10px 10px 10px;
    }
    .date-head {
        font-weight: 600;
        color: #0a0a0ac7;
    }
    .date-head-active {
        font-weight: 600;
        color: #ffffff;
    }
    .ptr-logo {
        width: 11%;
        padding-left: 25px;
    }
    .post-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 8px;
        gap: 8px;
        width: 33%;
        height: 56px;
        background: #104112;
        border-radius: 20px;
        color: white;
        border: unset;
        font-size: 19px;
    }
    .checks {
        display: inline-flex;
        padding: 10px 10px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 20px;
        margin: 15px 0 15px 0;
        width: 63%;
        height: 30px;
        flex-direction: row;
        align-items: center;
    }
    .comm-input {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 20px;
        gap: 8px;
        height: 70px;
        background: #FFFFFF;
        border-radius: 20px;
        margin: 16px 0 16px 0;
        width: 61%;
    }
    .place-club {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 12px 10px;
        gap: 12px;
        background: #b1acacbf;
        border-radius: 16px;
        margin: 10px 10px 10px 10px;
    }
    .inputs {
        box-sizing: border-box;
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        padding: 0px 16px 0px 0px;
        gap: 8px;
        height: 44px;
        background: #FFFFFF;
        border-bottom: 1px solid #FFFFFF;
        border-radius: 100px 100px 100px 81px;
        margin: 20px 10px 20px 10px;
        width: 31%;
    }
    .center {
        width: 90%;
        margin: 25px 25px 25px 25px;
    }
    .date-deskpick {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 14px 0px;
        gap: 4px;
        width: 136px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 20px;
        margin: 10px 10px 10px 10px;
        cursor: pointer;
    }
    .datepicker-deskcontrainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .date-con{
        display: flex;
        justify-content: center;
    }
    .navarrs{
        background: #FFFFFF;
        border-radius: 18.25px;
        position: relative;
        width: 20px;
        border: solid 10px white;
    }
    .footerform {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}


input[type="checkbox"]{
    width: 18px;
}
input[type="text"],textarea{
    border: 0;
    width: 100%;
    height: 90%;
}
.container{
    border-radius: 40px;
    background: linear-gradient(180.27deg, rgba(181, 192, 205, 0.4) 9.35%, rgba(230, 230, 230, 0.144) 56.01%, rgba(78, 128, 70, 0.08) 99.79%);
    backdrop-filter: blur(6px);
    height: auto;
    color: #222;
    padding: 20px 10px;
}
.dtext{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #ffffffc4;
    opacity: 0.8;
}
.mtext{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
}

.inputs-datadef{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    padding: 0px 32px 0px 0px;
    gap: 8px;
    height: 44px;
    background: #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    border-radius: 69px 100px 100px 100px;
}

.inputs-data{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    padding: 0px 32px 0px 0px;
    gap: 8px;
    height: 44px;
    background: #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    border-radius: 69px 100px 0 0;
}

.inicons{
    background: #FFFFFF;
    border-radius: 18.25px;
    position: relative;
    width: 35px;
    border: solid 10px white;
}
.error {
    display: block;
    color: red;
    background: white;
    border-radius: 8px;
    font-size: 36px;
    text-align: center;
    font-weight: 700;
    position: relative;
}

.dataname{
    bottom: 27%;
    position: relative;
}
.arrow-icon{
    bottom: 20%;
    position: relative;
}

label {
    display: block;
    padding-left: 4px;
    font-size: 14px;
}
.privacy-check, .ofer-ch{
    display: flex;
}


.activep{
    background: #FFFFFF;
}
.place-selection{
    margin: 16px 0 16px 0;
}
.date-pick{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 44px;
    background: #FFFFFF;
    border-top: 1px solid #e3e3e3;
}
.date-pick:hover{
    box-shadow:2px 2px 2px rgb(139, 139, 139);
}
.date-end {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px 0px 0px;
    gap: 8px;
    height: 44px;
    background: #FFFFFF;
    border-radius: 0 0 100px 100px;
    border-top: 1px solid #e3e3e3;
}
.aval-places{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 10px;
    height: 32px;
    background: #dbdbdb;
    border-radius: 5px;
    flex: none;
    order: 1;
    flex-grow: 0;
    width: 75px;
}
.datepicker-place{
    margin-bottom: 20px;
}
.place-img{
    width: 17px;
}
.place-head {
    font-weight: 600;
    font-size: 15px;
}

</style>

